import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useRef, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../context/Theme/themeContext";
import registrationService from "../../services/registration/registrationService";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { L, capitalizeFirstLetter } from "../../lib/abpUtility";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { errorRef } from "../../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import { EventContext } from "../../context/Event/eventContext";
import RegistrationEditStepTwo from "../../components/registration/RegistrationEdit/RegistrationEditStepTwo";
import RegistrationEditStepOne from "../../components/registration/RegistrationEdit/RegistrationEditStepOne";
import RegistrationEditStepThree from "../../components/registration/RegistrationEdit/RegistrationEditStepThree";
import { Checkbox } from "primereact/checkbox";
import { ListBox } from "primereact/listbox";
import { useQuery } from "react-query";
import registrationItemsService from "../../services/registrationItems/registrationItemsService";
import { Skeleton } from "primereact/skeleton";
import "./registrationDetails.scss";
import { composeShopImageName } from "../Items/shopUtils";
import localizationService from "../../services/localization/localizationService";

let abp = window.abp;

const getTotalAmount = (items) => {
  return items.reduce(
    (a, b) => a + parseFloat(b["amount"].replaceAll(",", ".")),
    0
  );
};

function RegistrationDetails() {
  const { id } = useParams();
  const { lastEvent } = useContext(EventContext);
  const { data, refetch: refetchRegistration } = useQuery({
    queryKey: ["registration", id],
    queryFn: () => registrationService.get(id),
    enabled: !!id,
  });
  const { data: days } = useQuery({
    queryKey: ["days", lastEvent],
    queryFn: () => registrationService.getDays(lastEvent),
    enabled: !!lastEvent,
  });
  const { data: duesItems, refetch: refetchDues } = useQuery({
    queryKey: ["dues", id],
    queryFn: () => registrationService.getDuesRegistration(id),
    enabled: !!id,
  });

  let dues = duesItems?.items;
  let totalDue = dues ? getTotalAmount(dues) : 0;

  const { data: paymentsItems, refetch: refetchPayments } = useQuery({
    queryKey: ["payments", id],
    queryFn: () => registrationService.getPaymentsRegistration(id),
    enabled: !!id,
  });

  let payments = paymentsItems?.items;

  const { data: paymentTypesItems } = useQuery({
    queryKey: ["paymentTypes"],
    queryFn: () => registrationService.getPaymentTypes(),
  });

  let paymentTypes =
    paymentTypesItems?.map((i) => {
      return {
        ...i,
        text: L(i.text),
      };
    }) || [];

  let totalPaid = payments ? getTotalAmount(payments) : 0;
  let balance = totalDue - totalPaid;
  const [payPrice, setPayPrice] = useState(totalDue - totalPaid);

  const { data: usersInGroupItems, isLoading: usersInGroupLoading } = useQuery({
    queryKey: ["usersInGroup", id],
    queryFn: () =>
      registrationService.getBulkPaymentDetails({
        RegistrationId: id,
      }),
    enabled: !!id && abp.auth.hasPermission("Pages.Payments.Pay"),
  });

  let usersInGroupData = usersInGroupItems || [];

  const { data: registrationItemsResponse, refetch: refetchRegistrationItems } =
    useQuery({
      queryKey: ["registrationItems", id],
      queryFn: () => registrationItemsService.getAll({ Keyword: id }),
      enabled: !!id,
    });

  const registrationItems = useMemo(() => {
    return registrationItemsResponse?.items || [];
  }, [registrationItemsResponse]);

  const { colorScheme } = useContext(ThemeContext);
  const op = useRef(null);
  const ot = useRef(null);
  const og = useRef(null);
  const [chargePrice, setChargePrice] = useState(0);
  const [chargeComment, setChargeComment] = useState("");
  const [payComment, setPayComment] = useState("");
  const [paymentType, setPaymentType] = useState(2);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const overlayPanel = useRef(null);
  const { register, control, reset, setValue, getValues, watch } = useForm();
  const [payPriceGroup, setPayPriceGroup] = useState(0);
  const [membersToPayFor, setMembersToPayFor] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [shopItem, setShopItem] = useState({});
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);
  const [groupLeaders, setGroupLeaders] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [changeGroupLeaderDialogVisible, setChangeGroupLeaderDialogVisible] =
    useState(false);

  const [
    demotionGroupLeaderDialogVisible,
    setDemotionGroupLeaderDialogVisible,
  ] = useState(false);
  const [groupLeaderId, setGroupLeaderId] = useState(null);
  const [newGroupLeader, setNewGroupLeaderId] = useState(null);

  /* The above code is using the `useMemo` hook in React to determine whether a user can delete
  something based on certain conditions. It checks if the user is a group leader
  (`data.isGroupLeader`) and if the number of group members (`groupMembers.length`) is equal to 1.
  If both conditions are met, it sets `canDelete` to `true`, otherwise it sets it to `false`. The
  `useMemo` hook will recompute the value of `canDelete` whenever the `groupMembers` or `data`
  dependencies change. */
  const canDelete = useMemo(() => {
    if (!data || !groupParticipants) return false;

    if (data.isGroupLeader) {
      if (groupParticipants.length <= 1) return true;
      return false;
    }

    return true;
  }, [groupParticipants, data]);

  const refetchRegistrationData = () => {
    refetchRegistration();
    refetchRegistrationItems();
    refetchDues();
    refetchPayments();
  };

  useEffect(() => {
    setPayPrice(totalDue - totalPaid);
  }, [totalDue, totalPaid]);

  useEffect(() => {
    if (abp.auth.hasPermission("Pages.Payments.Pay")) {
      registrationService
        .getAllGroupLeaders({ MaxResultCount: 10000 })
        .then((res) => {
          setGroupLeaders(res.items);
        });
    }
  }, []);

  useEffect(() => {
    if (!data || !groupLeaders) return;

    let groupLeader = groupLeaders?.find((x) => x.fullName === data?.group);
    setGroupLeaderId(groupLeader?.userId);

    registrationService.getAllRegistrationsInGroups(data?.id).then((res) => {
      setGroupParticipants(res || []);
      let result = res?.filter(
        (x) => x.userId !== null && x.userId !== data?.userId
      );
      setGroupMembers(result);
    });
  }, [data, groupLeaders]);

  useEffect(() => {
    reset(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToEdit = () => {
    const url = `/registrations/${data.id}/edit`;
    navigate(url);
  };

  const deleteRegistration = () => {
    registrationService.deleteRegistrations({ id }).then((_) => {
      navigate(-1);
    });
  };

  const attachRegistrationToUser = () => {
    registrationService
      .assignUserToRegistration({ emailAddress: userEmail, registrationId: id })
      .then((res) => {
        setUserEmail("");
        overlayPanel.current.hide();
        refetchRegistrationData();
      });
  };

  const chargeButtonClick = (e) => {
    op.current?.toggle(e);
  };

  const payButtonClick = (e) => {
    ot.current?.toggle(e);
  };

  const payGroupsButtonClick = (e) => {
    og.current?.toggle(e);
  };

  const charge = () => {
    registrationService
      .chargeDueRegistration({
        registrationId: id,
        amount: chargePrice,
        comment: chargeComment,
      })
      .then(() => {
        op.current?.hide();
        refetchRegistrationData();
      })
      .catch((e) => {
        errorRef.current.show({
          severity: "error",
          summary: "Error",
          detail: L("ChargeError"),
          life: 4000,
        });
      });
  };

  const pay = () => {
    registrationService
      .payRegistration({
        registrationId: id,
        amount: payPrice,
        paymentTypeId: paymentType,
        comment: payComment,
      })
      .then(() => {
        ot.current?.hide();
        refetchRegistrationData();
      })
      .catch((e) => {
        errorRef.current.show({
          severity: "error",
          summary: "Error",
          detail: L("PayError"),
          life: 4000,
        });
      });
  };

  const payGroup = () => {
    registrationService
      .bulkPay({
        registrationIds: membersToPayFor,
        paymentTypeId: paymentType,
        comment: payComment,
        amount: payPriceGroup,
      })
      .then(() => {
        og.current?.hide();
        resetAfterGroupPay();
      })
      .catch((e) => {
        errorRef.current.show({
          severity: "error",
          summary: "Error",
          detail: L("PayError"),
          life: 4000,
        });
      });
  };

  const resetAfterGroupPay = () => {
    setMembersToPayFor([]);
    setPayPriceGroup(0);
    window.location.reload(false);
  };

  function clearAmountOnClick(e, type) {
    e.preventDefault();
    type === "pay" ? setPayPrice(null) : setChargePrice(null);
  }

  const refetchData = () => {
    let values = getValues();
    reset(values);
  };

  const onGroupMemberCheck = (e) => {
    let selectedMembers = [...membersToPayFor];
    if (e.checked) {
      selectedMembers.push(e.value.registrationId);
      setPayPriceGroup((curr) => curr + parseFloat(e.value.totalDue));
    } else {
      selectedMembers.splice(
        selectedMembers.indexOf(e.value.registrationId),
        1
      );
      setPayPriceGroup((curr) => curr - parseFloat(e.value.totalDue));
    }
    setMembersToPayFor(selectedMembers);
  };

  const deleteDialogFooter = (
    <>
      <Button
        label={L("No")}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowDeleteDialog(false)}
      />
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteRegistration();
          setShowDeleteDialog(false);
        }}
      />
    </>
  );

  const deleteItemDialogFooter = (
    <>
      <Button
        label={L("No")}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setShopItem({});
          setShowDeleteItemDialog(false);
        }}
      />
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteShopItem(shopItem.id);
          setShowDeleteItemDialog(false);
        }}
      />
    </>
  );

  const changeGroupLeader = async () => {
    await registrationService.attachUser(groupLeaderId, id);
    refetchRegistrationData();
  };

  const deleteShopItem = async (shopItemId) => {
    await registrationItemsService.delete({ id: shopItemId });
    setShopItem({});
    setShowDeleteItemDialog(false);
    refetchRegistrationData();
  };

  const handleDeleteShopItem = (shopItem) => {
    setShopItem(shopItem);
    setShowDeleteItemDialog(true);
  };

  const dettach = async () => {
    await registrationService.dettachUser(id);
    refetchRegistrationData();
  };

  const demotion = async () => {
    await registrationService.changeGroupLeader({
      oldGroupLeader: {
        userId: data?.groupUserId,
      },
      newGroupLeader: {
        userId: newGroupLeader,
      },
    });
    refetchRegistrationData();
  };

  const changeGroupLeaderDialogFooter = () => {
    return (
      <>
        <Button
          label={L("No")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => setChangeGroupLeaderDialogVisible(false)}
        />
        <Button
          label={L("Yes")}
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => {
            changeGroupLeader();
            setChangeGroupLeaderDialogVisible(false);
          }}
        />
      </>
    );
  };

  const demotionGroupLeaderDialogFooter = () => {
    return (
      <>
        <Button
          label={L("No")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => setDemotionGroupLeaderDialogVisible(false)}
        />
        <Button
          label={L("Yes")}
          icon="pi pi-check"
          className="p-button-text"
          disabled={groupMembers.length === 0}
          onClick={() => {
            demotion();
            setDemotionGroupLeaderDialogVisible(false);
          }}
        />
      </>
    );
  };

  if (!data || !days) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div
      className={`container ${colorScheme === "dark" && "container-dark"}`}
      style={{ padding: "10px 0px" }}
    >
      <Dialog
        header={L("Confirmation")}
        visible={showDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <p className="m-0">
          {L("AreYouSureWantToDelete").replaceAll(
            "{0}",
            `${data?.firstName} ${data?.lastName}`
          )}
        </p>
      </Dialog>
      <Dialog
        header={L("Confirmation")}
        visible={showDeleteItemDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowDeleteItemDialog(false)}
        footer={deleteItemDialogFooter}
      >
        <p className="m-0">
          {L("AreYouSureWantToDelete").replaceAll(
            "{0}",
            `${localizationService.localizePipe(
              shopItem?.itemDto?.specifications?.title
            )}`
          )}
        </p>
      </Dialog>
      <div className="p-3">
        {/* Buttons */}
        <div
          id="buttons"
          className="flex mb-3"
          style={{
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <div>
            <Button
              label={L("GoBack")}
              className="p-button-text p-button-help"
              onClick={() => navigate(-1)}
            />
          </div>
          <div>
            {(data.isProcesseded === false ||
              abp.auth.hasPermission("Pages.Payments.Pay")) && (
              <Button label={L("Edit")} onClick={() => goToEdit()} />
            )}
          </div>
          {!data?.userId && (
            <div>
              <Button
                label={L("AttachUser")}
                className="p-button p-button-info"
                onClick={(e) => overlayPanel.current.toggle(e)}
              />
            </div>
          )}
          <OverlayPanel ref={overlayPanel}>
            <div className="flex flex-column">
              <InputText
                onChange={(e) => setUserEmail(e.target.value)}
                className="mb-3"
                value={userEmail}
                placeholder={L("Email")}
              />
              <Button
                label={L("AttachUserBtn")}
                disabled={!userEmail}
                onClick={() => attachRegistrationToUser()}
              />
            </div>
          </OverlayPanel>
          <div>
            {abp.auth.hasPermission("Pages.Registration.Remove") &&
              canDelete && (
                <Button
                  label={L("Delete")}
                  className="p-button-danger"
                  onClick={() => setShowDeleteDialog(true)}
                />
              )}
          </div>
        </div>
        <Divider />
        {/* GroupData */}
        <div className="flex flex-column md:col-12 surface-ground text-800 ">
          <div
            className="flex"
            style={{
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <div className="flex mt-2">
              <Button
                type="button"
                severity="info"
                onClick={() => {
                  const filters = JSON.parse(localStorage.getItem("filters"));
                  const newFilters = {
                    ...filters,
                    "Filter.GroupLeadersId": [data.groupUserId],
                  };
                  localStorage.setItem("filters", JSON.stringify(newFilters));
                  navigate("/registrations");
                }}
              >
                {data?.assignToGroup == null && L("NotInAGroup")}
                {!(data?.assignToGroup == null) &&
                  L("Group") + " : " + data.group}
              </Button>
            </div>
            {!(data?.assignToGroup == null) && (
              <CopyToClipboard
                text={
                  window.location.origin +
                  "/registration/step1?gl=" +
                  data?.assignToGroup
                }
                onCopy={() => {
                  errorRef.current.show({
                    severity: "success",
                    summary: L("LinkCoppied"),
                    detail: L("SendLink"),
                    life: 4000,
                  });
                }}
              >
                <Button label={L("GetGroupLink")} />
              </CopyToClipboard>
            )}
            {abp.auth.hasPermission("Pages.Payments.Pay") &&
              !data?.groupUserId && (
                <div>
                  <Button
                    label={
                      data?.groupUserId
                        ? L("ChangeGroupLeader")
                        : L("AttachGroupBtn")
                    }
                    className="p-button p-button-success"
                    onClick={() => setChangeGroupLeaderDialogVisible(true)}
                  />
                </div>
              )}
            {abp.auth.hasPermission("Pages.Payments.Pay") &&
              data?.groupUserId &&
              !data?.isGroupLeader && (
                <div>
                  <Button
                    label={L("DettachGroupBtn")}
                    className="p-button p-button-danger"
                    onClick={() => dettach()}
                  />
                </div>
              )}
            {abp.auth.hasPermission("Pages.Payments.Pay") &&
              data?.groupUserId &&
              data?.isGroupLeader && (
                <div>
                  <Button
                    label={L("DemotionGroupLeader")}
                    className="p-button p-button-danger"
                    onClick={() => setDemotionGroupLeaderDialogVisible(true)}
                  />
                </div>
              )}
            <Dialog
              header={L("ChangeGroupLeader")}
              visible={changeGroupLeaderDialogVisible}
              draggable={false}
              style={{ width: "50vw" }}
              footer={changeGroupLeaderDialogFooter}
              onHide={() => setChangeGroupLeaderDialogVisible(false)}
            >
              <ListBox
                options={groupLeaders}
                placeholder="Select a group leader"
                optionLabel="fullName"
                optionValue="userId"
                onChange={(e) => setGroupLeaderId(e.value)}
                value={groupLeaderId}
              />
            </Dialog>
            <Dialog
              header={L("SelectGroupLeader")}
              visible={demotionGroupLeaderDialogVisible}
              draggable={false}
              style={{ width: "50vw" }}
              footer={demotionGroupLeaderDialogFooter}
              onHide={() => setDemotionGroupLeaderDialogVisible(false)}
            >
              <ListBox
                options={groupMembers}
                placeholder={L("SelectGroupLeader")}
                optionLabel="fullName"
                optionValue="userId"
                onChange={(e) => setNewGroupLeaderId(e.value)}
                value={newGroupLeader}
              />
            </Dialog>
          </div>
        </div>
        <Divider />
        {/* Payments */}
        <div>
          <React.Fragment>
            <div className="grid surface-section  text-center  text-800">
              <div className="col-12 md:col-6 p-2 text-center md:text-left">
                {abp.auth.isGranted("Pages.Dues.Charge") && (
                  <div
                    id="buttons"
                    className="flex mb-3"
                    style={{
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button label={L("Charge")} onClick={chargeButtonClick} />
                    <OverlayPanel
                      ref={op}
                      showCloseIcon
                      id="overlay_panel"
                      style={{ width: "450px" }}
                      className="overlaypanel-demo"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <InputText
                          placeholder={L("Charge") + " " + L("Comment")}
                          value={chargeComment}
                          onChange={(e) => setChargeComment(e.target.value)}
                        />
                        <InputNumber
                          value={chargePrice}
                          onFocus={(e) => clearAmountOnClick(e, "charge")}
                          onValueChange={(e) => setChargePrice(e.value)}
                          mode="currency"
                          currency="BGN"
                          locale="bg-BG"
                          style={{ width: "min-content" }}
                        />
                        <Button
                          label={L("Charge")}
                          className="p-button-help"
                          onClick={charge}
                          style={{ width: "min-content" }}
                        />
                      </div>
                    </OverlayPanel>
                  </div>
                )}
                <div>
                  <DataTable
                    className="md:text-right"
                    value={dues}
                    responsiveLayout="scroll"
                    footer={L("Total") + " " + totalDue.toFixed(2)}
                  >
                    <Column field="comment" header={L("Charges")}></Column>
                    <Column
                      className="md:text-right"
                      field="amount"
                      header={L("BGNAmount")}
                      alignHeader="right"
                    ></Column>
                  </DataTable>
                </div>
              </div>
              <div className="col-12 md:col-6 p-2 text-center md:text-left">
                {abp.auth.isGranted("Pages.Payments.Pay") && (
                  <div
                    id="buttons"
                    className="flex mb-3"
                    style={{
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      label={L("Payment")}
                      className="p-button-help"
                      onClick={payButtonClick}
                    />
                    <OverlayPanel
                      ref={ot}
                      showCloseIcon
                      id="overlay_panel"
                      style={{ width: "450px" }}
                      className="overlaypanel-demo"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <InputText
                          placeholder={L("Payment") + " " + L("Comment")}
                          value={payComment}
                          onChange={(e) => setPayComment(e.target.value)}
                        />

                        <Dropdown
                          value={paymentType}
                          optionLabel="text"
                          optionValue="id"
                          options={paymentTypes}
                          onChange={(e) => setPaymentType(e.value)}
                          placeholder={L("Method")}
                        />

                        <InputNumber
                          value={payPrice}
                          onFocus={(e) => clearAmountOnClick(e, "pay")}
                          onValueChange={(e) => setPayPrice(e.value)}
                          mode="currency"
                          currency="BGN"
                          locale="bg-BG"
                          style={{ width: "min-content" }}
                        />

                        <Button
                          label={L("Payment")}
                          className="p-button-help"
                          onClick={pay}
                          style={{ width: "min-content" }}
                        />
                      </div>
                    </OverlayPanel>

                    {data?.group && (
                      <React.Fragment>
                        <Button
                          label={L("Payment") + " " + L("Group")}
                          className="p-button-help"
                          disabled={usersInGroupLoading}
                          loading={usersInGroupLoading}
                          onClick={payGroupsButtonClick}
                        />

                        <OverlayPanel
                          ref={og}
                          showCloseIcon
                          id="overlay_panel"
                          style={{ width: "450px" }}
                          className="overlaypanel-demo"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <InputText
                              placeholder={L("Payment") + " " + L("Comment")}
                              value={payComment}
                              onChange={(e) => setPayComment(e.target.value)}
                            />

                            <Dropdown
                              value={paymentType}
                              optionLabel="text"
                              optionValue="id"
                              options={paymentTypes}
                              onChange={(e) => setPaymentType(e.value)}
                              placeholder={L("Method")}
                            />

                            <h6 className="mb-0 mt-0">{L("PayFor")}</h6>
                            {usersInGroupData?.map((reg) => (
                              <div key={reg.registrationId}>
                                <Checkbox
                                  value={reg}
                                  onChange={(e) => onGroupMemberCheck(e)}
                                  checked={membersToPayFor.includes(
                                    reg.registrationId
                                  )}
                                  disabled={!reg.totalDue}
                                ></Checkbox>

                                <label
                                  htmlFor="cb1"
                                  className="p-checkbox-label p-2"
                                >
                                  {reg?.fullName +
                                    " " +
                                    parseFloat(reg.totalDue)?.toFixed(2)}
                                </label>
                              </div>
                            ))}

                            <InputNumber
                              value={payPriceGroup}
                              onChange={(e) => setPayPriceGroup(e.value)}
                              mode="currency"
                              currency="BGN"
                              locale="bg-BG"
                              style={{ width: "min-content" }}
                            />

                            <Button
                              label={L("Payment")}
                              className="p-button-help"
                              onClick={payGroup}
                              style={{ width: "min-content" }}
                            />
                          </div>
                        </OverlayPanel>
                      </React.Fragment>
                    )}
                  </div>
                )}
                <div>
                  <DataTable
                    className="md:text-right"
                    value={payments}
                    emptyMessage={L("NoPayments")}
                    responsiveLayout="scroll"
                    footer={L("Total") + " " + totalPaid.toFixed(2)}
                  >
                    <Column field="comment" header={L("Payment")}></Column>
                    <Column field="payment" header={L("Method")}></Column>
                    <Column
                      className="md:text-right"
                      field="amount"
                      header={L("BGNAmount")}
                      alignHeader="right"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>

            <div className="flex flex-column col-12 surface-section text-800 align-items-center mt-1 mb-3">
              <Button
                label={balance.toFixed(2)}
                className="p-button-raised p-button-secondary mr-1 ml-1 mb-1 mt-1 "
                style={{
                  height: "55px",
                  width: "150px",
                  fontSize: "25px",
                }}
                disabled="readonly"
              />
            </div>
          </React.Fragment>
        </div>
        <Divider />
        {/* PersonalData */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <div className="col-12 md:col-4 text-center md:text-left">
            {data && days && (
              <RegistrationEditStepOne
                register={register}
                data={data}
                control={control}
                days={days}
                setValue={setValue}
                watch={watch}
                refetchData={refetchData}
                getValues={getValues}
                isView={true}
                reset={reset}
              />
            )}
          </div>
          <div className="col-12 md:col-3 p-2 text-center md:text-left">
            <RegistrationEditStepTwo
              register={register}
              control={control}
              isView={true}
              getValues={getValues}
            />
          </div>
          <div className="col-12 md:col-3 p-2 text-center md:text-left ">
            <RegistrationEditStepThree
              register={register}
              data={data}
              activities={null}
              control={control}
              isView={true}
            />
          </div>
        </div>
        <Divider />
        {/* Shop items */}
        {(abp.auth.hasPermission("Pages.Items.Admin") ||
          abp.auth.hasPermission("Pages.Registration.GroupLeader")) && (
          <Button onClick={() => navigate(`/shop/?registrationId=${id}`)}>
            {`${L("PreOrderFor")} ${data?.firstName}`}
          </Button>
        )}
        <div className="registration-products">
          {registrationItems.map((shopItem) => (
            <div className="shop-product" key={shopItem.id}>
              <div className="top">
                <div className="category">
                  <i className="pi pi-tag"></i>
                  <p>
                    {L(
                      "ShopCardType" +
                        capitalizeFirstLetter(shopItem.itemDto.type)
                    )}
                  </p>
                </div>
                <Button
                  onClick={() => handleDeleteShopItem(shopItem)}
                  severity="secondary"
                  icon="pi pi-times"
                ></Button>
              </div>
              <div className="image">
                {shopItem.itemDto.images?.[0]?.url ? (
                  <RenderProductCorrespondingImage
                    images={shopItem.itemDto.images || []}
                    data={shopItem.specifications}
                  />
                ) : (
                  <Skeleton height="100%" />
                )}
              </div>
              <p className="title">{shopItem.itemDto.title}</p>
              <div className="shop-specifications">
                <div className="genders">
                  {shopItem.itemDto.specifications?.genders?.map((gender) => (
                    <div
                      key={gender}
                      style={{ cursor: "default" }}
                      className={`gender ${
                        shopItem.specifications.gender === gender && "selected"
                      }`}
                    >
                      <span>{L(gender.toUpperCase())}</span>
                    </div>
                  ))}
                </div>
                <div className="sizes">
                  {shopItem.itemDto.specifications?.sizes?.map((size) => (
                    <div
                      key={size}
                      style={{ cursor: "default" }}
                      className={`size ${
                        shopItem.specifications?.size === size && "selected"
                      }`}
                    >
                      <span>{L(size).toUpperCase()}</span>
                    </div>
                  ))}
                </div>
                <div className="colors">
                  <div
                    className={`color selected`}
                    style={{
                      backgroundColor: `#${shopItem.specifications?.color}`,
                      cursor: "default",
                    }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="information_bottons">
                  <Button
                    outlined
                    label={"Price: " + shopItem.itemDto.price + " лв."}
                    className="p-button-help"
                    style={{ pointerEvents: "none" }}
                  />
                  <Button
                    outlined
                    label={`${L("Quantity")}:  ${
                      shopItem.specifications?.quantity
                    }`}
                    className="p-button-info"
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <ul>
                  {Object.keys(shopItem.specifications).map(
                    (key) =>
                      !excludedProperties.find((i) => i === key) && (
                        <li key={key}>
                          <span>{L(capitalizeFirstLetter(key))}</span>:{" "}
                          {shopItem.specifications[key]}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <Divider />
        {/* QRCode */}
        <div className="grid md:col-12 surface-section text-800">
          <div className="col-12 md:col-4 p-2 text-center md:text-left">
            <section>
              <div>
                <img
                  src={data?.qrCodeLink}
                  alt="qrCode"
                  width={300}
                  height={300}
                  border={1}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export const excludedProperties = [
  "productId",
  "color",
  "gender",
  "size",
  "quantity",
];

/**
 * The function `RenderProductCorrespondingImage` renders an image based on the product data and a list
 * of images.
 * @returns An image element is being returned with the `alt` attribute set to an empty string and the
 * `src` attribute set to the `url` property of the `shopItem` object if it exists, otherwise it falls
 * back to the `url` property of the first object in the `images` array.
 */
export const RenderProductCorrespondingImage = ({ images, data }) => {
  const imageName = composeShopImageName(
    data.productId,
    data.gender,
    data.color
  );
  const shopItem = images.find((image) => image.fullName.includes(imageName));
  return <img alt="" src={shopItem ? shopItem.url : images?.[0]?.url} />;
};

export default RegistrationDetails;
