import { Button } from "primereact/button";
import React, { useContext, useMemo, useState } from "react";
import { RegistrationContext } from "../../../context/Registration/registrationContext";
import jsPDF from "jspdf";
import { useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import storageService from "../../../services/storage/storageService";
import http from "../../../services/httpService";
import { errorRef } from "../../../App";
import registrationService from "../../../services/registration/registrationService";
import { ProgressSpinner } from "primereact/progressspinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { L } from "../../../lib/abpUtility";
import ProtectedAuthLink from "../../routing/ProtectedAuthLink";
import { RenderProductImage } from "../../../pages/Shop/Shop";
import { useQuery } from "react-query";
import shopService from "../../../services/shop/shopService";
import { useUser } from "../../../hooks/user/useUser";
import { EventContext } from "../../../context/Event/eventContext";
import userStorageService from "../../../services/storage/userStorageService";

function StepFour() {
  const user = useUser();
  const { setStep } = React.useContext(RegistrationContext);
  const [emailLoading, setEmailLoading] = useState(false);
  const { lastEvent } = useContext(EventContext);
  const [id, setId] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const history = useNavigate();
  const { data: shopResponse } = useQuery({
    queryKey: ["shopItems"],
    queryFn: () => shopService.getAll(),
    enabled: !!user?.registrationId && !!lastEvent?.isShopActive,
  });

  const shopImages = useMemo(() => {
    const shopItemsImages =
      shopResponse?.items?.map((i) => {
        return i.images;
      }) || [];

    let urls = [].concat(...shopItemsImages);
    urls = shuffleArray(urls);
    return urls;
  }, [shopResponse]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const inviteGroupUrl = useMemo(() => {
    return storageService.getItem("inviteGroupURL");
  }, []);

  const group = useMemo(() => {
    return storageService.getItem("group");
  }, []);

  const totalDue = useMemo(() => {
    return storageService.getItem("totalDue");
  }, []);

  const canHaveGroup = useMemo(() => {
    return storageService.getItem("canHaveGroup");
  }, []);

  const qrCodeLink = useMemo(() => {
    return storageService.getItem("qrCodeLink");
  }, []);

  React.useEffect(() => {
    setId(storageService.getItem("id"));

    setStep(3);
  }, [setStep]);

  const downloadImage = () => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = qrCodeLink;
    img.onload = () => {
      const canvas = createCanvas(img);
      const a = document.createElement("a");
      a.download = "QR_Code_Nova_Valna.png";
      a.href = canvas.toDataURL("image/png");
      a.click();
    };
  };

  const downloadPdf = () => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = qrCodeLink;
    img.onload = () => {
      const canvas = createCanvas(img);

      let fileWidth = 80;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL("image/png");
      let PDF = new jsPDF("p", "mm", "a4");
      let position = 0;
      PDF.addImage(FILEURI, "PNG", 0, position, fileWidth, fileHeight);
      PDF.save("QR_Code_Nova_Valna.pdf");
    };
  };

  const onSubmit = (data) => {
    if (data.email) {
      setEmailLoading(true);
      http
        .post("api/services/app/Registrations/SendRegistrationEmail", {
          registrationId: id,
          email: data.email,
        })
        .then((res) => {
          if (res.status === 200) {
            setShowEmail(false);
            setValue("email", "");
            setEmailLoading(false);
            errorRef.current.show({
              severity: "success",
              summary: L("Congratulations"),
              detail: L("EmailCongratulations"),
              life: 3000,
            });
          } else {
            errorRef.current.show({
              severity: "error",
              summary: L("Error"),
              detail: L("EmailError"),
              sticky: true,
            });
          }
        });
    }
  };

  const newRegistration = async () => {
    userStorageService.resetUser();
    history("/registration/step1");
  };

  const newGroupRegistration = async () => {
    userStorageService.resetUser();
    const inviteUrl = storageService.getItem("inviteGroupURL");
    window.location.replace(inviteUrl);
  };

  const viewRegistration = async () => {
    history(`/registrations/${id}/details`);
  };

  return (
    <div className="step">
      <div className="step_four_title">
        <h3>{L("ThankYouForRegistering")}</h3>
        <p>
          <b>{L("Save")}</b> {L("SaveQRCodeText")}{" "}
          {L("PrepareTotalDue").replace("{0}", totalDue)}
        </p>
      </div>
      <div className="qr_code_renderer">
        {id !== null && id ? (
          <img
            width={300}
            height={300}
            src={qrCodeLink}
            alt="qr-code"
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div className="step_four_actions">
        <Button
          label={L("Image")}
          icon="pi pi-download"
          onClick={() => downloadImage()}
        />
        <Button
          label={L("PDF")}
          icon="pi pi-download"
          onClick={() => downloadPdf()}
        />
        <Button
          label={L("Email")}
          icon="pi pi-envelope"
          onClick={() => setShowEmail((showEmail) => !showEmail)}
        />
      </div>
      {showEmail && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="step_four_email_show"
        >
          <h5>
            Email
            <span style={{ color: "red" }}>
              {" "}
              {errors.email && L("MustBeValid")}
            </span>
          </h5>
          <InputText
            {...register("email", {
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />

          <Button
            label={L("Send")}
            className="next-button"
            disabled={emailLoading}
          />
        </form>
      )}
      <br />
      <br />
      {user?.registrationId && lastEvent?.isShopActive && (
        <div className="step_four_shop">
          <RenderProductImage images={shopImages} ms={2000} />
          <div className="shop_info">
            <h3>{L("ShopInvitationHeader")}</h3>
            <p>
              {L("ShopInvitationDescription")}:
              <Link
                to={
                  user.registrationId === id
                    ? "/shop"
                    : `/shop?registrationId=${id}`
                }
              >
                <Button className="p-button-help">{L("Shop")}</Button>
              </Link>
            </p>
          </div>
        </div>
      )}

      <br />
      <br />
      <Button
        label={L("NewRegistration")}
        onClick={() => newRegistration()}
        className="next-button p-button-outlined"
      />
      <br />
      <br />
      {inviteGroupUrl && !canHaveGroup && group && (
        <React.Fragment>
          <Button
            label={L("NewRegistrationFor", group)}
            onClick={() => newGroupRegistration()}
            severity="danger"
            className="next-button p-button-outlined"
          />
          <br />
          <br />
        </React.Fragment>
      )}

      <ProtectedAuthLink auth={true}>
        <Button
          label={L("ViewRegistration")}
          onClick={() => viewRegistration()}
          className="next-button p-button-outlined p-button-secondary"
        />
      </ProtectedAuthLink>
      <br />
      <br />
      {canHaveGroup && (
        <CopyToClipboard
          text={canHaveGroup}
          onCopy={() => {
            errorRef.current.show({
              severity: "success",
              summary: L("LinkCoppied"),
              detail: L("SendLink"),
              life: 4000,
            });
          }}
        >
          <Button label={L("GetGroupLink")} className="next-button" />
        </CopyToClipboard>
      )}
    </div>
  );
}

export default StepFour;

const createCanvas = (img) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  return canvas;
};

function shuffleArray(array) {
  let shuffledArray = array.slice();

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i

    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}
